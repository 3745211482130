<template>
    <div class="layout">
        <top-bar/>
        <div class="container">
<!--            <slide-bar-->
<!--                v-if="$route.name !== 'noticeDetails' && $route.name !== 'policiesDetails' &&-->
<!--                 $route.name !== 'Index' && $route.name !== 'customize' &&-->
<!--                  $route.name !== 'EmotionDetail' && $route.name !== 'AssessDetailContrast' &&-->
<!--                  $route.name !== 'EmotionDetailContrast' && $route.name !== 'GestationalAge'&&-->
<!--                   $route.name !== 'ReportPage'&& $route.name !== 'messagePage'&&-->
<!--                    $route.name !== 'AssessDetailTeam'"-->
<!--            />-->
            <div class="content">
                <router-view :key="key"/>
            </div>
        </div>

    </div>
</template>

<script>
import slideBar from '../components/sideBar'
import topBar from "../components/topBar";

export default {
    name: "Layout",
    components: {slideBar, topBar},
    data() {
        return {};
    },
    computed: {
        key() {
            return this.$route.path + Math.random();
        }
    },
    watch: {
    },
    methods: {},
    mounted() {
    },
}
</script>

<style scoped lang="scss">
.layout {
    width: 100vw;
    height: 100vh;
    background: #F2F2F2;
}

.container {
    height: calc(100vh - 0.7rem);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.content {
    width: 100%;
    height: 100%;
    /*overflow: hidden;*/
    overflow: auto;
    background: #434655;
    &::-webkit-scrollbar {
         display: none;
     }
}
</style>
